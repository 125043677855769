import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getCurrentPageProps, getPath, getPageHash, scrollToHash } from '../../../utils';

import '../../../styles/index.scss';

import HOC from '../organisms/root';
import Grid, { GridRow, GridCol } from '../atoms/grid';
import { HeroContainer, ContentContainer, ContentWrapper, Tile } from '../molecules/container';
import MainNavigation from '../organisms/mainNavigation';
import { Devider, Spacer } from '../atoms/devider';
import { SpotlightContent } from '../molecules/spotlight';
import Header from '../molecules/header';
import Footer from '../molecules/footer';
import { H1, H2, H3 } from '../atoms/header';
import Address from '../atoms/address';
import { DefinitionList } from '../atoms/definitionList';
import CookiesTooltip from '../atoms/cookiesTooltip';

class Layout extends Component {

  componentDidMount() {
    setTimeout(() => !!getPageHash(getPath()) && scrollToHash(getPageHash(getPath())), 100);
  }

  render() {
    const { children, data } = this.props;
    return data ? (
      <HOC
        googleAnalyticsId={data?.siteConfigs?.tracking?.ga?.id}>
        {children}
        <MainNavigation navLinks={data.mainNavLinks} pageType="main" />
        <Header>
          <HeroContainer
            contentHeader={getCurrentPageProps(data.heroHeaders, getPath())}
            contentFeatured={getCurrentPageProps(data.heroFeatured, getPath())} />
        </Header>
        <Spacer spacing={2} />
        <Grid gap={20} noPadding={true} offset={true}>
          <GridRow direction="row">
            <GridCol colSize={7} stretched={true}>
              <SpotlightContent content={getCurrentPageProps(data.spotlightContent, getPath())} />
            </GridCol>
            <GridCol colSize={5}>
              <Grid noPadding={true}>
                <GridRow direction="column">
                  <GridCol>
                    <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "spotlightDetails")} />
                  </GridCol>
                </GridRow>
              </Grid>
            </GridCol>
          </GridRow>
          <Devider margin={10} />
          <GridRow direction="row">
            <GridCol colSize={7}>
              <a anchor='about-us'></a>
              <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "aboutUs")} />
            </GridCol>
            <GridCol colSize={5}>
              <Grid noPadding={true}>
                <GridRow direction="column">
                  <GridCol>
                    <a anchor='expertise'></a>
                    <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "mission")} />
                  </GridCol>
                </GridRow>
                <GridRow direction="column">
                  <GridCol>
                    <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "vision")} />
                  </GridCol>
                </GridRow>
              </Grid>
            </GridCol>
          </GridRow>
        </Grid>
        <a anchor='publicaties'></a>
        {
          data.casesContent &&
          (
            <>
              <Grid gap={10} noPadding={true} offset={true}>
                <Devider margin={10} />
                <H1 type="section">{data.casesContent.cases.title}</H1>
                <GridRow direction="row">
                  {
                    [...data.casesContent.cases.items.slice(0, 2)].map((caseItem, index) => (
                      <GridCol colSize={6} key={index}>
                        <ContentContainer content={caseItem} />
                      </GridCol>
                    ))
                  }
                </GridRow>
                <Spacer spacing={10} />
                <GridRow direction="row">
                  {
                    [...data.casesContent.cases.items.slice(2, 4)].map((caseItem, index) => (
                      <GridCol colSize={6} key={index}>
                        <ContentContainer content={caseItem} />
                      </GridCol>
                    ))
                  }
                </GridRow>
                <Spacer spacing={10} />
                <GridRow direction="row">
                  {
                    [...data.casesContent.cases.items.slice(4, 7)].map((caseItem, index) => (
                      <GridCol colSize={6} key={index}>
                        <ContentContainer content={caseItem} />
                      </GridCol>
                    ))
                  }
                </GridRow>
                <Spacer spacing={10} />
                <GridRow direction="row">
                  {
                    [...data.casesContent.cases.items.slice(7, 10)].map((caseItem, index) => (
                      <GridCol colSize={6} key={index}>
                        <ContentContainer content={caseItem} />
                      </GridCol>
                    ))
                  }
                </GridRow>
              </Grid>
            </>
          )
        }
        <Grid gap={10} noPadding={true} offset={true}>
          <Devider margin={10} />
          <GridRow direction="row">
            <a anchor='media'></a>
            <GridCol colSize={8} colOffsetSize={9}>
              <Grid gap={10} noPadding={true}>
                <H1 type="section">{data.press.title}</H1>
                <H2 type="section">{data.press.subtitle1}</H2>
                <GridRow direction="row">
                  {
                    [...data.press.items.slice(0, 2)].map((caseItem, index) => (
                      <GridCol colSize={2} key={index}>
                        <ContentContainer content={caseItem} />
                      </GridCol>
                    ))
                  }
                </GridRow>
                <Spacer spacing={10} />
                <GridRow direction="row">
                  {
                    [...data.press.items.slice(2, 4)].map((caseItem, index) => (
                      <GridCol colSize={2} key={index}>
                        <ContentContainer content={caseItem} />
                      </GridCol>
                    ))
                  }
                </GridRow>
                <Spacer spacing={10} />
                <H2 type="section">{data.press.subtitle2}</H2>
                <GridRow direction="row">
                  {
                    [...data.press.items.slice(4, 6)].map((caseItem, index) => (
                      <GridCol colSize={2} key={index}>
                        <ContentContainer content={caseItem} />
                      </GridCol>
                    ))
                  }
                </GridRow>
              </Grid>
            </GridCol>
            <GridCol colSize={4} colOffsetSize={12} isFlex={false}>
              <a anchor="contact"></a>
              <ContentWrapper isSticky={true}>
                <Grid noPadding={true} offset={false}>
                  <GridRow direction="row">
                    <GridCol>
                      <ContentWrapper theme="dark">
                        <H3>{'Contact Info'}</H3>
                        <Address content={getCurrentPageProps(data.contactInfo, '*')} className="address" />
                        <Spacer spacing={6} />
                        <DefinitionList prefixed={true} content={getCurrentPageProps(data.contactInfo, '*')} className="definition-list--dark" />
                      </ContentWrapper>
                    </GridCol>
                  </GridRow>
                </Grid>
              </ContentWrapper>
            </GridCol>
          </GridRow>
        </Grid>
        <Grid gap={10} noPadding={true} offset={true}>
          <Devider margin={10} />
          <GridRow direction="row">
            <GridCol colSize={10}>
              <Footer title={data.footerTitle} navLinks={{ items: data.footerNavLinks.items }} />
            </GridCol>
            <GridCol colSize={2} align="right">
              <Footer navLinks={{ ctas: data.footerNavLinks.ctas }} />
            </GridCol>
          </GridRow>
        </Grid>
        <Spacer spacing={20} />
        <CookiesTooltip>
          <ContentContainer content={data.cookiesConsent} />
        </CookiesTooltip>
      </HOC>
    ) : (
        <>
          No Data
        </>
      );
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
