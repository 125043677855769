import React from 'react';

import Link from '../atoms/link';
import Image from '../atoms/image';
import { H1, H2, H3 } from '../atoms/header';
import { UL } from '../atoms/definitionList';
import { FeaturedHeader } from '../molecules/header';
import { P } from '../atoms/paragraph';
import { createMarkup } from '../../../utils';

export const HeroContainer = ({ contentHeader, contentFeatured, fullWidthCtas = false, ...props }) => {
  return (contentHeader && (
    <section className="hero-container">
      {
        contentHeader.title && <H1>{contentHeader.title}</H1>
      }
      {
        contentHeader.excerpt &&
        <div className={`hero-container__content-wrapper`}>
          {
            contentHeader.excerpt.map((paragraph, index) => (
              <P key={`${+new Date()}-${index}--content-excerpt`} className="content--excerpt" dangerouslySetInnerHTML={createMarkup(paragraph)} />
            ))
          }
        </div>
      }
      {
        contentHeader.ctas.map(({ to, href, icon, label, ...link }, index) => (
          <Link key={`${+new Date()}-${index}--link-cta`} icon={icon} className={`link--cta hero-container-link--cta`} to={to || href} {...link}>{label}</Link>
        ))
      }
      <FeaturedHeader content={contentFeatured} fullWidthCtas={fullWidthCtas} />
    </section>
  )) || (<></>);
};

export const ContentContainer = ({ content, isSectionTitle = false, theme, isButtonBlock, ...props }) => {
  const isInvisibleClass = content && content.image && content.image.isInvisible ? 'link--btn-block' : '';
  const themeClass = theme === 'dark' ? ' content__container--dark' : '';
  const buttonBlockClass = isButtonBlock ? ` button__block` : ``;
  const titleType = isSectionTitle ? 'section' : '';
  return (content && (
    <section className={`content__container ${themeClass}${buttonBlockClass}`}>
      {
        content.image &&
        (
          content.image.href ?
            (
              <>
                <Link
                  className={`link--image ${isInvisibleClass}`}
                  to={content.image.href}
                  target={content.image.target}
                  label={content.image.title}
                  title={content.image.title}>
                  <Image
                    src={content.image.src}
                    alt={content.image.title}
                    title={content.image.title}
                    isInvisible={content.image.isInvisible}
                    className="featured-header__image"
                    caption={content.image.caption} />
                  {
                    content.image.isInvisible && content.label && <H2 className={`link--btn-block-header`}>{content.label}</H2>
                  }
                </Link>
              </>
            ) : (
              <Image
                src={content.image.src}
                alt={content.image.title}
                title={content.image.title}
                className="featured-header__image"
                isInvisible={props.isInvisible}
                caption={content.image.caption} />
            )
        )
      }
      {
        content.tags && content.tags.length > 0 ?
          <div className="tags__wrapper">
            {
              content.tags.map((tagItem, index) => (
                <span className="tag-item" key={`tag-${index}`}>{tagItem}</span>
              ))
            }
          </div>
          :
          <></>
      }
      {
        content.title && <H1 type={titleType}>{content.title}</H1>
      }
      {
        content.excerpt &&
        <div className="content__body">
          {
            content.excerpt && content.excerpt.map((paragraph, index) => (
              <P key={`${+new Date()}-${index}--content-excerpt`} className="content--excerpt" dangerouslySetInnerHTML={createMarkup(paragraph)} />
            ))
          }
        </div>
      }
      {
        content.sections && content.sections.length > 0 &&
        <div className="content__body">
          {
            content.sections && content.sections.map((section, index) => (
              <div className="content__section" key={`${+new Date()}-${index}--content-section`}>
                {
                  section.title && <H2>{section.title}</H2>
                }
                {
                  section.excerpt && <Excerpt content={section.excerpt} child={true}></Excerpt>

                }
              </div>
            ))
          }
        </div>
      }
      {
        content.list && (
          <UL list={content.list}></UL>
        )
      }
      {
        content.links && content.links.map(({ label, href, ...itemProps }, index) => (
          <Link key={`${+new Date()}-${index}--link-link`} className="link--line" to={href} label={label} title={label} {...itemProps}>{label}</Link>
        ))
      }
      {
        content.ctas && content.ctas.map(({ label, href, ...itemProps }, index) => (
          <Link key={`${+new Date()}-${index}--link-cta`} className="link--cta" to={href} label={label} title={label} {...itemProps}>{label}</Link>
        ))
      }
    </section >
  )) || (<></>);
};

export const Tile = ({ content, direction = 'col', theme, ...props }) => {
  const tileDirectionClassName = direction === 'row' ? 'tile__wrapper--horizontal' : '';
  return (
    <div className={`tile__wrapper ${tileDirectionClassName}`}>
      {
        content.image &&
        (
          content.image.href ?
            (
              <>
                <Link
                  className={`link--image`}
                  to={content.image.href}
                  target={content.image.target}
                  label={content.image.title}
                  title={content.image.title}>
                  <Image
                    src={content.image.src}
                    alt={content.image.title}
                    title={content.image.title}
                    isInvisible={content.image.isInvisible}
                    className="tile__image"
                    caption={content.image.caption} />
                  {
                    content.image.isInvisible && content.label && <H2 className={`link--btn-block-header`}>{content.label}</H2>
                  }
                </Link>
              </>
            ) : (
              <Image
                src={content.image.src}
                alt={content.image.title}
                title={content.image.title}
                className="tile__image"
                isInvisible={props.isInvisible}
                caption={content.image.caption} />
            )
        )
      }
      <div className="tile__content">
        {
          content.title && <H3>{content.title}</H3>
        }
        {
          content.excerpt &&
          <div className="content__body">
            {
              content.excerpt && content.excerpt.map((paragraph, index) => (
                <P key={`${+new Date()}-${index}--content-excerpt`} className="content--excerpt" dangerouslySetInnerHTML={createMarkup(paragraph)} />
              ))
            }
          </div>
        }
      </div>
    </div>
  );
};

export const ContentWrapper = ({ children, theme, isSticky, ...props }) => {
  const themeClass = theme === 'dark' ? ' content__container--dark' : '';
  const stickyClass = isSticky ? ' content__container--sticky' : '';
  return (
    <section className={`content__container${themeClass}${stickyClass}`}>
      {children}
    </section>
  );
};

export const Excerpt = ({ content, child = false, section = false }) => {
  return (
    <div className={`content__body ${section ? 'content__section' : ''}`}>
      {
        content && content.map((paragraph, index) => (
          <React.Fragment key={`${+new Date()}-${index}--content-excerpt`}>
            {
              typeof paragraph === 'string' ?
                <P className="content--excerpt" dangerouslySetInnerHTML={createMarkup(paragraph)} />
                :
                <>
                  {
                    paragraph.title && !child ? <H2>{paragraph.title}</H2> : <H3>{paragraph.title}</H3>
                  }
                  {
                    paragraph.excerpt && <Excerpt content={paragraph.excerpt} child={true}></Excerpt>
                  }
                  {
                    paragraph.list && <UL key={`${+new Date()}-${index}--content-excerpt`} list={paragraph.list}></UL>
                  }
                  {
                    paragraph.footer && <Excerpt content={paragraph.footer} child={true}></Excerpt>
                  }
                </>
            }
          </React.Fragment>
        ))
      }
    </div>
  );
};